
const changeParamUrl = (key, value) => {
    // var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `${key}=${value}`;
    // window.history.pushState({path: refresh}, '', refresh);
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.replaceState(null, null, url); // or pushState


}
export default changeParamUrl