import {useEffect} from "react";


const Active = ({}) => {

    useEffect(() =>{
        var zoneId = document.getElementById('#siteId');

        if(zoneId){
            var value = zoneId.getAttribute('value');
            var string = `[zoneid='${value}']`;
            var zone = document.querySelector(string);

            if(zone){
                var allZone = document.querySelectorAll('[zoneid]')
                if(allZone && allZone.length){
                    allZone.forEach(t => {
                        t.classList.remove('active');
                    })
                }
                zone.classList.add('active');

            }
        }
        var articleinzone = document.querySelector('[articleinzone]');
        if(articleinzone){
            var valueZone =articleinzone.getAttribute('articleinzone');
            console.log(valueZone,'valueZone')
            fillActiveInZone(valueZone)
        }
      //  articleinzone
    } , [])

    const fillActiveInZone = (zoneId) =>{
        var string = `[zoneid='${zoneId}']`;
        var zone = document.querySelector(string);
        if(zone){
            var allZone = document.querySelectorAll('[zoneid]')
            if(allZone && allZone.length){
                allZone.forEach(t => {
                    t.classList.remove('active');
                })
            }
            zone.classList.add('active');

        }
    }
    // useEffect(() => {
    //     var url = window.location.pathname;
    //     var hasActive =false;
    //     var listMenu = document.querySelectorAll('.nav-item-wrapper');
    //     if (listMenu && listMenu.length) {
    //         Array.from(listMenu).forEach(item => {
    //
    //             const treeWalker = document.createTreeWalker(item, NodeFilter.SHOW_ELEMENT);
    //             while (treeWalker.nextNode()) {
    //                 const node = treeWalker.currentNode;
    //                 var urlHref = node.getAttribute('href');
    //
    //                 if (url == urlHref && !hasActive) {
    //                     item.classList.add('active');
    //                     hasActive = true;
    //                     return;
    //                     break
    //                 }
    //             }
    //         })
    //     }
    //
    // }, [])

    return null
};
export default Active;