const cleanJSON = (json) => {
    let newJson = {}

    for (let key in json) {

        if (json && json[key] || typeof json[key] == 'number'){
            newJson[key] = json[key]
        }
    }
    return newJson;
}
export default cleanJSON
