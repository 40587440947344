const ENUM_DATA_MS_ROLE = {
    MS_ROLE: 'ms-role', // rule đánh dấu block, mns list,mns item,
    MS_ROLE_LIST: 'list',
    MS_ROLE_ITEM: 'item',
    MS_ROLE_BLOCK: 'block',
    MS_ROLE_LABEL: 'ms-role-label',
    MS_ROLE_TEMPLATE_HUB: 'template-hub',
    MS_ROLE_TEMPLATE_HUB_IN_SAMPLE: 'ms-template-hub-sample',
    MS_ALLOW_TEMPLATE_HUB: 'ms-allow-templatehub',
    MS_DATA_SAMPLE_ID: 'ms-data-sample-id',
    MS_ALLOW_SAMPLE: 'ms-allow-sample', // nếu allow thì các block con (nếu có) sẽ show thành dạng list 
    MS_DATA_LAYOUT_ID: 'ms-data-layout-id', // nếu 1 block có attribute thì đc phép replace/insert block
    MS_PROPERTY:'ms-property',//key đánh dấu atrr trường bài viết
    MS_DETAIL_PROPERTY:'ms-detail-property',
    MS_PROPERTY_LINK:'ms-property-link', //đánh dấu riêng trường link để phân biệt với text;
    MS_DATA_BIND_KEY:'data-bind-key', //key mapping data
    MS_ALLOW_PREVIEW_SAMPLE: 'ms_allow_preview_sample',// đánh dấu block nào đc phép sửa ở trong iframe. dùng cho việc sửa mẫu
    MS_PLUGIN:'ms-plugin',
    MS_PLUGIN_TYPE:'ms-plugin-type',
    MS_DATA_BIND_KEY_DETAIL : 'data-bind-key-detail',
    MS_TEMPLATE_BOX_PREVIEW: 'ms-template-box-preview', // đánh dấu vào các block con của template container
    MS_TEMPLATE_BOX_LAYOUT_TYPE: 'ms-template-box-layout-type',
    MS_TEMPLATE_BOX_LAYOUT_VALUE: 'ms-template-box-layout-value',
    MS_ORIGINAL_STYLE: 'ms-original-style',
    MS_ROLE_VIDEO: 'video',
    MS_AVATAR_VIDEO: 'ms-avatar-video',
    MS_CONTENT_DATA:'ms-content-data', //đánh dấu vùng content trang tìm kiếm
    ALLOW_ACTIVE_ITEM:'allow-active-item', //cho phep active item trong list
    MS_ALLOW_CHANGE_BACKGROUND:'ms-allow-change-background',
    ALLOW_BIND_DATA:'allow-bind-data', //cho phép mapping data
    MS_IGNORE_RENDER: 'ms-ignore-render', // không cho phép render ra ngoài front-end
    MS_ALLOW_ACTION: 'ms-allow-action', // chỉ có khối block , item , list được duplicate , các item ngoài ảnh,text, mà muốn duplicate thì cần khai báo... allow all action
    MS_ALLOW_DUPLICATE: 'ms-allow-duplicate', // chỉ có khối block , item , list được duplicate , các item ngoài ảnh,text, mà muốn duplicate thì cần khai báo... only duplicate
    MS_DISABLE_SORT:"ms-disable-sort",
    MS_AUTO_SHOW:"ms-auto-show", //auto show 1 khối nào đó khi vào vào
    MS_NO_ACTION:"ms-no-action" // tắt các action trên element
}
export const ALLOW_CLICK_MS_ROLE = [ENUM_DATA_MS_ROLE.MS_ROLE_BLOCK, ENUM_DATA_MS_ROLE.MS_ROLE_TEMPLATE_HUB];

//allow click khi click vao children
export const ALLOW_CLICK_ROLE_CHILDREN = [ENUM_DATA_MS_ROLE.MS_ROLE_ITEM];
export default ENUM_DATA_MS_ROLE