import React, {Suspense, useEffect} from 'react';
import {render} from 'react-dom';
import ReactDOM from 'react-dom';
import lazyLoad from "./utils/lazyLoad";
import './styles/index.css'
import LoadMore from './Loadmore'
// import Login from './Login'
import Active from './Active'
// import {UserManager, Log, Oidc} from "oidc-client";
import CopyUrl from './CopyUrl'
import Share from './Share'
// import Search from './search/index2'
import MappingData from "./MappingData";
// import ImageMap from './ImageMap'
// import ConnectPlugin from "./connectplugin";
// import minisitePluginMapData from './MappingData/MinisitePluginMapdata'
//

import './css/custom.css'
const checkLazyComponent = (path,selector) =>{
  let element = document.querySelector(selector);

  
  if (element) {
      console.log(element,selector,path)
      const Component = lazyLoad(() => import(`./${path}`));
      return <Component />
  }

}

document.addEventListener("DOMContentLoaded", function () {

    let root = document.getElementById('minisitate-plugins-search');
    if (!root) {
        root = document.createElement('div');
        root.setAttribute('id', 'minisitate-plugins-search');
        document.body.appendChild(root)
    }
    ReactDOM.render(
        <Suspense fullback={'loading'}>
            {checkLazyComponent('search/index2',"[ms-content-data]")}
            {/*{checkLazyComponent('Loadmore',`[ms-plugin="loadmore"]`)}*/}
            {/*{checkLazyComponent('CopyUrl',"[ms-plugin='copyhref']")}*/}


            {/*{checkLazyComponent('Loadmore',`[ms-plugin="loadmore"]`)}*/}
            {/*{checkLazyComponent('MappingData',"#scriptArrayBindData")}*/}
            {/*{checkLazyComponent('Active',"#siteId")}*/}
            {/*<Search/>*/}
            {/*<LoadMore/>*/}
            {/*<CopyUrl/>*/}
            <Active/>
            <CopyUrl />
            {/*<Login />*/}



            <Share/>

            <MappingData/>
            <LoadMore />
            {/*<ImageMap />*/}
            {/*<ConnectPlugin />*/}
        </Suspense>,
        root
    );

});

// window.MinisitePlugin = {
//     minisitePluginMapData
// }
