const parseJSON = (str) => {
    if (typeof str === 'string')
        try {
            return JSON.parse(str);
        } catch (e) {
            return str;
        }
    return str
}

export default parseJSON;