
const findNodeByContent = (text, root, key) => {
    const treeWalker = document.createTreeWalker(root, NodeFilter.SHOW_ELEMENT);

    const nodeList = [];
    while (treeWalker.nextNode()) {
        const node = treeWalker.currentNode;
        if (hasOnlyTextNode(node) && node.textContent.includes(text)) {

            if (key && key == 'body') {
                nodeList.push({ node, type: 'body' });
            }
            else {
                nodeList.push({ node, type: 'text' });
            }
        }
        if (node.getAttribute('src') == text) {
            nodeList.push({ node, type: 'src' });
        }
        if (node.getAttribute('href') == text) {
            nodeList.push({ node, type: 'href' });
        }
        let style = node.getAttribute('style');
        if (style && (style && style.indexOf('background-image') > -1) && style.includes(text)) {
            nodeList.push({ node, type: 'background-image' });
        }
    }
    return nodeList;
}

const isElemNode = (node) => {
    return node && node.nodeType === Node.ELEMENT_NODE;
}
const getNodeName = (node) => {
    if (isElemNode(node)) {
        return node.tagName;
    }
    return 'TEXT';
}
const hasOnlyTextNode = (node) => {
    var childNodes = node.childNodes;
    return childNodes.length === 1 && getNodeName(childNodes[0]) === 'TEXT';
}
export default findNodeByContent;