import React, {useEffect, useState} from 'react';
import axios from "axios";
import parseJSON from '../utils/parseJSON'
import DOMutils from '../utils/DOMutils'
import changeParamUrl from "../utils/changeParamUrl";
import configs from '../configs'
import cleanJSON from '../utils/cleanJSON'
console.log('load-more11')

const LoadMore = ({}) => {
    console.log('load-more111')
    useEffect(() => {
        let msPlugin = document.querySelectorAll('[ms-plugin="loadmore"]');

        let element;
        if (msPlugin && msPlugin.length) {
            for (var i = 0; i < msPlugin.length; i++) {
                let plugin = msPlugin[i];
                let attr = plugin.getAttribute('ms-plugin');
                let pageId = plugin.getAttribute('pageid');
                let loadmoreFor = plugin.getAttribute('for');

                if (attr == 'loadmore' && pageId && loadmoreFor) {
                    let pluginTypes = plugin.querySelectorAll('[ms-plugin-type]');
                    if (pluginTypes && pluginTypes.length) {
                        for (var i = 0; i < pluginTypes.length; i++) {
                            let typePlugin = pluginTypes[i];
                            element = typePlugin
                            let hasActive = typePlugin.classList.contains('active');
                            if (hasActive) {
                                let namePlugin = typePlugin.getAttribute('ms-plugin-type');

                                const findLoadmoreFor = document.querySelector(`#${loadmoreFor}`);
                                let bindList = findLoadmoreFor.querySelector('[ms-role="list"]');
                                const bindKey = bindList.getAttribute('data-bind-key');
                                if (namePlugin == 'button') {
                                    const button = typePlugin.querySelector('button');
                                    button.addEventListener('click', event => {
                                        if (findLoadmoreFor) {

                                            let index = button.getAttribute('pageindex');
                                            let pageIndex;
                                            if (!index) {
                                                pageIndex = 2;
                                            } else {
                                                pageIndex = Number(index) + 1
                                            }
                                            button.setAttribute('pageindex', pageIndex)
                                            if (bindList) {
                                                if (bindKey) {
                                                    getData(pageId, bindKey, bindList, bindKey, pageIndex, namePlugin, button)
                                                }
                                            }
                                        }
                                    })
                                } else {
                                    const listItemPagination = typePlugin.querySelectorAll('[ms-role="item"]');

                                    if (listItemPagination && listItemPagination.length) {
                                        for (var i = 0; i < listItemPagination.length; i++) {
                                            const item = listItemPagination[i];
                                            item.addEventListener('click', event => {

                                                const msIndex = item.getAttribute('ms-index');
                                                var execUrl = /page-([0-9]+)/.exec(window.location.href)

                                                let pageIndex;

                                                if (!isNaN(msIndex)) {
                                                   // getData(pageId, bindKey, bindList, bindKey, Number(msIndex), namePlugin, item)
                                                    pageIndex = Number(msIndex)
                                                } else {
                                                    let pageIndexUrl = execUrl && execUrl[1]

                                                    if (!pageIndexUrl && msIndex == 'prev') {
                                                        pageIndex = 1;
                                                    } else if (!pageIndexUrl && msIndex == 'next') {
                                                        pageIndex = 2;
                                                    } else if (msIndex == 'prev' && pageIndexUrl > 1) {
                                                        pageIndex = Number(pageIndexUrl) - 1
                                                    } else if (msIndex == 'next') {
                                                        pageIndex = Number(pageIndexUrl) + 1
                                                    } else {
                                                        pageIndex = 1;
                                                    }

                                                  //  getData(pageId, bindKey, bindList, bindKey, Number(pageIndex), namePlugin, item)
                                                }
                                                var pathName  =window.location.pathname.replace(".chn" , "")

                                                var refresh = window.location.protocol + "//" + window.location.host + pathName + `/page-${pageIndex}.chn`;

                                                var href = window.location.href
                                                if(execUrl && execUrl.length){
                                                    refresh = href.replace(/page-([0-9]+).chn/ , `page-${pageIndex}.chn`)
                                                }
                                                window.location.href=refresh;
                                                //changeParamUrl('pageIndex', Number(pageIndex))
                                               // hanldeReLoadPagination(listItemPagination,pageIndex)
                                            })
                                        }
                                    }
                                    // listPagination.addEventListener('click', event => {
                                    //     console.log(event,'eventeventevent');
                                    // })

                                }

                            }
                        }
                    }

                }
            }
        }

        return () => {

            if (element) {
                element.addEventListener('click', event => {

                })
            }
        }
    }, [])

    useEffect(() =>{
        //let pageIndexUrl = getParameterByName(null, 'pageIndex');
        var execUrl =  /page-([0-9]+)/.exec(window.location.href)

        let pageIndexUrl = Number(execUrl && execUrl[1]);

        if(pageIndexUrl){
           const pluginLoad =  document.querySelector('[ms-plugin="loadmore"]');

           if(pluginLoad){
               const pluginType = pluginLoad.querySelector('[ms-plugin-type="pagination"]');
               if(pluginType){
                   const pageId = pluginLoad.getAttribute('pageid');
                   let loadmoreFor = pluginLoad.getAttribute('for');

                   const findLoadmoreFor = document.querySelector(`#${loadmoreFor}`);
                   let bindList = findLoadmoreFor.querySelector('[ms-role="list"]');
                   const bindKey = bindList.getAttribute('data-bind-key');
                   let itemsPlugin = pluginType.querySelectorAll('[ms-role="item"]');
                   if(pageId && loadmoreFor){
                      // getData(pageId, bindKey, bindList, bindKey, Number(pageIndexUrl), 'pagination')
                      hanldeReLoadPagination(itemsPlugin,Number(pageIndexUrl))
                   }
               }

           }

        }

    } , [])

    const hanldeReLoadPagination = (items,pageIndex) =>{

        if(pageIndex >= 4){
            for (var i = 0; i < items.length; i++) {
                let item = items[i];
                let tagP = item.querySelector('p')
                if(i == 1){
                    item.setAttribute('ms-index' ,pageIndex - 2 )
                    item.classList.remove('active')
                    tagP.textContent =pageIndex - 2
                }
                if(i == 2){
                    item.setAttribute('ms-index' ,pageIndex - 1 )
                    item.classList.remove('active')
                    tagP.textContent =pageIndex - 1
                }
                if(i == 3){
                    item.setAttribute('ms-index' ,pageIndex )
                    item.classList.remove('active')
                    tagP.textContent = pageIndex
                }
                if(i == 4){
                    item.setAttribute('ms-index' ,pageIndex + 1 )
                    item.classList.remove('active')
                    tagP.textContent = pageIndex + 1
                }
                if(i == 5){
                    item.setAttribute('ms-index' ,pageIndex + 2 )
                    item.classList.remove('active')
                    tagP.textContent = pageIndex + 2
                }
                if(item.getAttribute('ms-index') == pageIndex){
                    item.classList.add('active');
                }
            }
        }else{
            for (var i = 0; i < items.length; i++) {
                let item = items[i];
                let tagP = item.querySelector('p')
                if(i == 1){
                    item.setAttribute('ms-index' ,1)
                    item.classList.remove('active')
                    tagP.textContent = 1
                }
                if(i == 2){
                    item.setAttribute('ms-index' ,2 )
                    item.classList.remove('active')
                    tagP.textContent = 2
                }
                if(i == 3){
                    item.setAttribute('ms-index' , 3 )
                    item.classList.remove('active')
                    tagP.textContent = 3
                }
                if(i == 4){
                    item.setAttribute('ms-index' ,4 )
                    item.classList.remove('active')
                    tagP.textContent = 4
                }
                if(i == 5){
                    item.setAttribute('ms-index' ,5 )
                    item.classList.remove('active')
                    tagP.textContent = 5
                }
                if(i == pageIndex){
                    item.classList.add('active');
                }
            }
        }
    }
    const getData = (pageId, bindKey, root, key, pageIndex, typePlugin, pluginLoad) => {

        const APIDomain = configs.apiDomain;
        var host = window.location.hostname.replace(/(https?:\/\/)?(www.)?/i, '');
        var domEle = document.querySelector('[articleinzone]');
        var zoneId;
        if(domEle){
            zoneId =  domEle.getAttribute('articleinzone')
        }
        let url = `${APIDomain}/searchNews?url=${host}&pageindex=${pageIndex}&pageId=${pageId}&bindKey=${bindKey}&pathname=${window.location.pathname}`;
       // let url = `${APIDomain}/searchNews?url=m4.cnnd.vn&pageindex=${pageIndex}&pageId=${pageId}&bindKey=${bindKey}`;
        const itemHtml = root.querySelector('[ms-role="item"]');
        if(itemHtml){
            for(var i = 0 ; i < 5;i++){
                const cloneItem = itemHtml.cloneNode(true);
                cloneItem.classList.add('item-loading');
                   cloneItem.classList.add('fake-item');
                root.appendChild(cloneItem)
            }
        }
        let dataJson = [];
        var obj = {
            url:host,
            pageindex:pageIndex,
            pageId:pageId,
            bindKey:bindKey,
            pathname:window.location.pathname,
            zoneId
        }
        var newDataArray = []
        axios.get(url ,{
            params:cleanJSON(obj)
        }).then(res => {
            const {data, template} = res && res.data || '';

            if (data && template) {
                 dataJson = parseJSON(data || '[]');
                newDataArray = dataJson
                if ((typePlugin && typePlugin) == 'pagination') {
                    removeNodeChild(root)
                }
                var pagesize =   Number(root.parentNode.getAttribute('page-size'));

                if(pagesize && (pagesize > dataJson.length)){
                      pluginLoad.remove();
                }
                if (dataJson && dataJson.length) {

                    const parser = new DOMParser();
                    const string = template

                    const doc = parser.parseFromString(string, "text/html");
                    const templateItem = doc && doc.body && doc.body.childNodes && doc.body.childNodes[0];
                    for (var i = 0; i < dataJson.length; i++) {
                        let clone = templateItem.cloneNode(true);

                        fillDataToItem(clone, dataJson[i], key);
                        clone.setAttribute('loarmoreId' , dataJson[i]["newsId"])
                        clone.setAttribute('zoneId' , dataJson[i]["zoneId"])
                         clone.classList.remove('item-loading')
                        root.appendChild(clone)
                    }
                } else {

                    pluginLoad.remove();
                }
            } else {
                if ((typePlugin && typePlugin) !== 'pagination') {
                    pluginLoad.remove();
                    var plugin = document.querySelector('[ms-plugin="loadmore"]');
                    if(plugin){
                        plugin.remove()
                    }
                }else{
                    if ((typePlugin && typePlugin) == 'pagination') {
                        removeNodeChild(root)
                        let div = document.createElement('div');
                        div.innerHTML = 'Không có dữ liệu';
                        root.appendChild(div)
                    }
                }

            }

        }).finally(() => {
            const itemLoading = root.querySelectorAll('.fake-item');

            if(itemLoading && itemLoading.length){
                for(var i = 0 ; i < itemLoading.length;i++){
                  const plugin = itemLoading[i].getAttribute('ms-plugin')

                    if(!plugin){
                        itemLoading[i].remove();
                    }

                }
            }
            if ((typePlugin && typePlugin) == 'pagination') {
                root.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
            var dataNewsIds = dataJson.map(item =>  item.newsId)
            var userArray = [];
            if(newDataArray && newDataArray.length){
                userArray = newDataArray && newDataArray.map(item =>{
                    const {user,newsId} = item;
                    return {user,newsId}
                })
            }
            console.log(userArray,'userArray')
            if(window.loadDataSuceess && typeof window.loadDataSuceess == 'function'){
                window.loadDataSuceess({loadmoreIds:dataNewsIds,author:userArray})

            }else{
                setTimeout(() =>{
                    if(window.loadDataSuceess && typeof window.loadDataSuceess == 'function'){
                        window.loadDataSuceess({loadmoreIds:dataNewsIds,author:userArray})
                    }
                } , 1500)
            }
        })
    }
    const removeNodeChild = (node) => {
        while (node.hasChildNodes()) {
            node.removeChild(node.lastChild);
        }
    }

    const fillDataToItem = (element, data, key) => {
        let propertys = DOMutils.getPropertyItem(data);
        //  let html = element.outerHTML;
        if (propertys && propertys.length) {
            for (var i = 0; i < propertys.length; i++) {
                let property = propertys[i];
                let pattern = '${' + key + '.' + property + '}';
                let findNode = DOMutils.findNodeByContent(pattern, element);

                if (findNode && findNode.length) {
                    findNode.forEach(node =>{
                        DOMutils.mapData(node, data[property], property);
                    })

                }
            }
        }
    }

    return null
}

function getParameterByName(url, name) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export default LoadMore;
