const loadJs = (url, callback) => {
    if (typeof url == 'undefined' || url == '')
        return;

    if (typeof (callback) != "function")
        callback = function () {
        };

    var jsList = [];
    if (url instanceof Array) {
        jsList = url;
    } else {
        jsList.push(url);
    }
    if (typeof (callback) == "undefined")
        callback = function () {
        };

    var linkCount = jsList.length;
    var current = 0;
    var head = document.getElementsByTagName('head')[0];

    jsList.forEach(function (url, i) {
        if (url.indexOf('?') != -1) {
            url = url.substr(0, url.indexOf('?'));
        }
        //huu added for prevent load duplicate script
        var arrScripts = document.querySelectorAll('script[src^="' + url + '"]');
        if (arrScripts == null || arrScripts.length == 0) {
            try {
                var scriptEl = document.createElement('script');
                scriptEl.type = 'text/javascript';
                //scriptEl.async = true;
                scriptEl.src = url + '?v=' + new Date().getTime();
                scriptEl.onload = scriptEl.onreadystatechange = function () {
                    current++;
                    if (current == linkCount)
                        callback();
                };
                scriptEl.addEventListener('error', function () {

                    current++;
                    if (current == linkCount)
                        callback();
                });
                head.appendChild(scriptEl);
            } catch (ex) {
                current++;
                if (current == linkCount)
                    callback();
            }
        } else {
            current++;
            if (current == linkCount)
                callback();
        }
    });
}

export default loadJs;
