import {useEffect} from "react";
import loadJs from '../utils/loadJs'

const Share = ({}) => {
    useEffect(() => {

        const actionShare = (query,link) =>{
            var buttonArray = document.querySelectorAll(query);
            if (buttonArray) {
                Array.from(buttonArray).forEach(item =>{
                    let encodedURL = encodeURI(window.location.href);
                    item.addEventListener('click', (e) => {
                        e.preventDefault();
                        window.open(`${link}${encodedURL}`);
                    })
                })

            }
        }
        // var button = document.querySelector('[ms-plugin="share-facebook"]');
        var likefb = document.querySelector('[ms-plugin="like-facebook"]');
        let encodedURL = encodeURI(window.location.href);
        if (likefb) {
           // let encodedURL = encodeURI(window.location.href);
            likefb.setAttribute('data-href', encodedURL);
        }
        actionShare('[ms-plugin="share-facebook"]' ,'https://www.facebook.com/sharer/sharer.php?u=')

        actionShare('[ms-plugin="share-tw"]' ,'https://twitter.com/intent/tweet?url=')
        actionShare('[ms-plugin="share-gmail"]' ,'mailto:?subject=')
        actionShare('[ms-plugin="share-instagram"]' ,'https://www.instagram.com/?url=')
        actionShare('[ms-plugin="share-pin"]' ,'https://www.pinterest.com/pin/create/bookmarklet/?pinFave=1&url=')

       // actionShare('[ms-plugin="share-gmail"]' ,'mailto:?subject=testbody=this is a test"')
        //sharezalo
        var zaloArray = document.querySelectorAll('[ms-plugin="share-zalo"]');
        if (zaloArray) {
            if(zaloArray && zaloArray.length){
                loadJs('https://sp.zalo.me/plugins/sdk.js' , () =>{
                    Array.from(zaloArray).forEach(item =>{
                        item.classList.add('zalo-share-button');
                        item.setAttribute('data_href' , encodedURL)
                        item.setAttribute('href' , 'javascript:;')
                        item.setAttribute('data-oaid' , '3853758560685742933')
                        item.setAttribute('data-layout' , '2')
                        item.setAttribute('data-customize' , 'true')

                        item.removeAttribute('target')
                    })
                });
            }

        }
    }, [])

    return null
};
export default Share;