import {useEffect} from "react";


const CopyUrl = ({}) => {
    useEffect(() => {
        var buttonArr = document.querySelectorAll('[ms-plugin="copyhref"]');

        if (buttonArr && buttonArr.length) {

            var copyText = document.createElement('input');
            copyText.value = window.location.href;
            Array.from(buttonArr).forEach(button =>{
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    copyText.select();
                    copyText.setSelectionRange(0, 99999);
                    navigator.clipboard.writeText(copyText.value);
                    alert("Đã copy địa chỉ bài viết thành công");

                })
            })

        }

    }, [])

    return null
};
export default CopyUrl;