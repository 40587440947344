const DOMutils = {
    getPropertyItem: (news) => {
        let arr = []
        for (const property in news) {
            arr.push(property)
        }

        return arr;
    },
    findNodeByContent: (text, root, key) => {
        const treeWalker = document.createTreeWalker(root, NodeFilter.SHOW_ELEMENT);

        const nodeList = [];
        while (treeWalker.nextNode()) {
            const node = treeWalker.currentNode;
            if (DOMutils.hasOnlyTextNode(node) && node.textContent.includes(text)) {

                if (key && key == 'body') {
                    nodeList.push({node, type: 'body'});
                } else {
                    nodeList.push({node, type: 'text'});
                }
            }
            if (node.getAttribute('src') == text) {
                nodeList.push({node, type: 'src'});
            }
            if (node.getAttribute('href') == text) {
                nodeList.push({node, type: 'href'});
            }
            let style = node.getAttribute('style');
            if (style && (style && style.indexOf('background-image') > -1) && style.includes(text)) {
                nodeList.push({node, type: 'background-image'});
            }
        }
        return nodeList;
    },
    mapData: (element, data, property) => {
        if (!element || !element.node) return;
        let localName = element && element.node.localName;

        if (element.type == 'background-image') {
            let regex = /url([^"]+)/i
            let style = element.node.getAttribute('style');
            let string = 'url(' + "'" + data + "'" + ')'
            element.node.setAttribute('style', style.replace(regex, string))
        } else if (localName === 'a' && element.type == 'href') {
            element.node.setAttribute('href', data)
        } else if (localName === 'img') {
            element.node.setAttribute('src', data);
        } else if (element.type == 'body') {
            element.node.innerHTML = data
        } else {
            element.node.innerText = data
        }
    },
    hasOnlyTextNode: (node) => {
        var childNodes = node.childNodes;
        return childNodes.length === 1 && DOMutils.getNodeName(childNodes[0]) === 'TEXT';
    },
    getNodeName: (node) => {
        if (DOMutils.isElemNode(node)) {
            return node.tagName;
        }
        return 'TEXT';
    },
    isElemNode :(node) => {
        return node && node.nodeType === Node.ELEMENT_NODE;
    }
}
export default DOMutils;