import React, {useEffect } from "react";
import randomId from "../../src/utils/randomId";
import loadJs from "../utils/loadJs";
const InitPlayer = ({data}) =>{
    var id = randomId()


    var typeIframe =data.search('iframe');

    useEffect(() =>{
        if(typeIframe > 0) return;
        var scriptPageSetting = document.getElementById('scriptSetting');
        if(!scriptPageSetting){
            scriptPageSetting = document.createElement('script');
            scriptPageSetting.setAttribute('id' , 'scriptSetting');
            scriptPageSetting.innerHTML = "              var pageSettings = {                 DomainAPI: 'http://localhost:2003',                 Domain: 'http://localhost:1889',                 sharefbApiDomain: 'https://sharefb.cnnd.vn',                 videoplayer: 'https://vcplayer.mediacdn.vn',                 VideoToken: 'L3NlY3VyZS92ZXJpZnkveHZxcmNhZGhlYmZpMHY1dm5zM2Ywd3d3a3Y2MDdkMDgvMTAwODExL2V5SmhiR2NpT2lKSVV6STFOaUlzSW5SNWNDSTZJa3BYVkNKOS5leUp5WldZaU9pSWlMQ0poY0hCclpYa2lPaUo0ZG5GeVkyRmthR1ZpWm1rd2RqVjJibk16WmpCM2QzZHJkall3TjJRd09DSXNJbkJzWVhsbGNpSTZNVEF3T0RFeExDSnBZWFFpT2pFMk1qUTFPVFF6TlRZc0ltVjRjQ0k2TkRjME5qWTFPRE0xTm4wLlc5OV9KUVI2R0QyOXd1NmNwSDhULXQ0ZWxOWE1IM3NiQjdKWl8tZzZ3enM=',                 ajaxDomain: 'http://local.apisocial.vn',                 commentSiteName: 'baochinhphubeta',                 allowComment: 'false'             }       "
            document.body.appendChild(scriptPageSetting);
        }
        if(window.videoHD && typeof window.videoHD == 'function'){
            window.videoHD.init(`#${id}` , {type:4})
        }else{
            loadJs(["https://minisiteb.qltns.mediacdn.vn/minisite/nghisitre/js/detail.js",'https://minisiteb.qltns.mediacdn.vn/minisite/nghisitre/js/videoplayer.js','https://adminplayer.sohatv.vn/resource/init-script/playerInitScript.js'] , () =>{
                window.videoHD.init(`#${id}` , {type:4})
            })
        }
    } , [])

    console.log(typeIframe,'typeIframetypeIframetypeIframe')
    if(typeIframe > 0) {
        console.log(data,'datadatadata')
        return<div  dangerouslySetInnerHTML={{__html: data}}></div>
    };

    var videoInfo = JSON.parse(data || '{}')
    const {KeyVideo,vid} = videoInfo;
    return (
        <div className='player-video-custom' id={id}>
            <div id={id} className="VCSortableInPreviewMode" type="VideoStream" data-namespace="minisiteb" embed-type="4" data-info={KeyVideo} data-vid={vid}>

            </div>

        </div>

    )
}
export  default InitPlayer;