import React, {Component, Suspense} from 'react'

export default function lazyLoad(getComponent, placeHolder) {

    const Main = React.lazy(getComponent);

    return (props) => (
        <Suspense fallback='loading'>
            <Main  {...props}/>
        </Suspense>
    )


}
